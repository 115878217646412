<template>
<v-app class="background-light">
    <v-container class="justify-center" fill-height>
        <change-password :user="user" :userName="UsrName" @close="close()" v-if="showChangePassword" />
        <!-- <v-toolbar height="50px" :color="'white darken-2'">
        <v-app-bar-nav-icon></v-app-bar-nav-icon>

        <v-toolbar-title>
            {{ 'Inmobiliaria Miraflores Peru' }}
        </v-toolbar-title>
    </v-toolbar> -->
        <v-app-bar color="white" dense style="border-radius: 4px;">
            <v-app-bar-nav-icon style="width: 0px; height: 30px; margin-left: 35px; margin-right: 40px">
                <v-img alt="" src="../../assets/logo.png" width="100" height="50"></v-img>
            </v-app-bar-nav-icon>
            <v-toolbar-title><b style="text-transform: uppercase;">Inmobiliaria Miraflores Peru</b></v-toolbar-title>

        </v-app-bar>

        <v-row style="margin-top:">

            <v-col lg="6" md="6" xs="12" cols="12" style="background:#f8f9fb;">
                <v-card light class="mx-auto my-4" width="70%" elevation="0" style="background:#f8f9fb;">
                    <v-card-text>
                        <div class="my-4 text-subtitle-1">
                            En tu cuenta encontrarás toda la información que necesitas a tu alcance, sin necesidad de ir a las oficinas.<br><br>

                            Guía de uso de Mi Cuenta. <a style="font-weight: bold;color: #234798">DESCARGAR AQUÍ</a></div>
                    </v-card-text>
                    <v-divider class="mx-4"></v-divider>
                    <v-card-title style="text-transform: uppercase;font-size:24px; font-weight: bold; color:#234798; margin: 16px 0;letter-spacing: -1px;">Información de IMP</v-card-title>
                    <v-card-text>
                        <v-row align="center" class="mx-0">
                            <v-rating :value="4.5" color="amber" dense half-increments readonly size="14"></v-rating>
                            <div class="grey--text ms-4">
                                4.5 (413)
                            </div>
                        </v-row>
                        <div class="my-4 text-subtitle-1">En tu cuenta encontrarás toda la información que necesitas a tu alcance, sin necesidad de ir a las oficinas.
                        </div><br>
                        <div class="my-4 text-subtitle-1">En tu cuenta encontrarás toda la información que necesitas a tu alcance, sin necesidad de ir a las oficinas.
                        </div>
                    </v-card-text>
                    <v-divider class="mx-4"></v-divider>
                    <v-card-title style="text-transform: uppercase;font-size:24px; font-weight: bold; color:#234798; margin: 16px 0;letter-spacing: -1px;">Horario de ATENCIÓN</v-card-title>
                    <v-card-text>
                        <v-chip-group active-class="deep-purple accent-4 white--text">
                            <v-chip small>Lunes a Viernes 9:00AM - 7:00PM</v-chip>
                        </v-chip-group>
                        <v-chip-group active-class="deep-purple accent-4 white--text">
                            <v-chip small>Sabados y Domingos 9:00AM - 7:00PM</v-chip>
                        </v-chip-group>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-divider vertical></v-divider>

            <!-- login -->
            <v-col lg="6" md="6" xs="12" cols="12" style="background:#fff;" v-if="login==true && recovery==false">
                <v-card  class="mx-auto my-0" width="90%" elevation="0" style="background:#fff;">
                    <v-card-title style="text-transform:;font-size:24px; font-weight: bold; color:#234798; margin: 16px 0;letter-spacing: -1px;">Iniciar sesión</v-card-title>
                    <v-card-text>
                        <div class="my-4 text-subtitle-1">¿No tienes una cuenta? <a @click="login=false" style="font-weight: bold;color: #234798">REGÍSTRATE</a></div>
                    </v-card-text>
                </v-card>

                <v-card width="400" class="mb-8" elevation="0" style="text-align: -webkit-center;margin-top: -37px;">
                    <v-card-text>
                        <a href="https://imperu.com.pe" target="_blank"><v-img alt="" src="../../assets/logo2.png" width="230"></v-img></a>
                    </v-card-text>
                </v-card>

                <v-card width="400" class="px-10 py-0" style="box-shadow: 0 0px 0px 0 !important; margin-top: -50px">
                    <v-card-text>
                        <s-text v-model="UsrName" ref="UsrName" label="USUARIO" class="px-0">
                            <template v-slot:prepend-inner>
                                <v-icon class="py-1">mdi mdi-account</v-icon>
                            </template>
                        </s-text>
                        <s-text v-model="UsrPassword" :type="visiblePassword ? 'text' : 'password'" ref="UsrPassword" label="CONTRASEÑA" class="px-0 mt-2" @keyupEnter="validateUser()" @click:append="visiblePassword = !visiblePassword">
                            <template v-slot:prepend-inner>
                                <v-icon class="py-1">mdi mdi-lock</v-icon>
                            </template>
                            <template v-slot:append>
                                <v-icon class="py-1" @click="visiblePassword = !visiblePassword">{{ visiblePassword ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
                            </template>
                        </s-text>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn elevation="0" class="px-2" width="30%" style="color: white" :color="'#ffaa1a'" :loading="validatingUser" :disabled="validatingUser" @click="validateUser()">
                            Ingresar
                        </v-btn><strong class="px-3"><a @click="recovery=true" style="font-weight: bold;color: #234798">RECUPERAR CONTRASEÑA</a></strong>
                    </v-card-actions>
                    <div class="text-center text-white opacity-8 mt-3">V. 02
                    </div>
                </v-card>
            </v-col>

            <!-- CREATE ACCOUNT -->

            <v-col lg="6" md="6" xs="12" cols="12" style="background:#fff" v-if="!login">
                <v-card class="mx-auto my-0" width="90%" elevation="0" style="background:#fff;">
                    <v-card-title style="text-transform:;font-size:24px; font-weight: bold; color:#234798; margin: 16px 0;letter-spacing: -1px;">Registrarse</v-card-title>
                    <v-card-text>
                        <div class="my-4 text-subtitle-1">Ya tienes una cuenta? <a @click="login=true" style="font-weight: bold;color: #234798">INICIAR SESIÓN</a></div>
                    </v-card-text>
                </v-card>
                <v-card class="px-10 py-0" style="box-shadow: 0 0px 0px 0 !important; margin-top: -20px">

                    <v-card-text>

                        <s-text v-model="UsrName" ref="UsrName" label="Nº DE DOCUMENTO" class="px-0">
                            <template v-slot:prepend-inner>
                                <v-icon class="py-1">mdi mdi-account</v-icon>
                            </template>
                        </s-text>

                        <s-text v-model="UsrEmail" ref="UsrEmail" label="CORREO ELECTRÓNICO" class="px-0 mt-4">
                            <template v-slot:prepend-inner>
                                <v-icon class="py-1">mdi mdi-email</v-icon>
                            </template>
                        </s-text>

                        <s-text v-model="UsrEmailConf" ref="UsrEmailConf" label="CONFIRMAR CORREO ELECTRÓNICO" class="px-0 mt-4">
                            <template v-slot:prepend-inner>
                                <v-icon class="py-1">mdi mdi-email</v-icon>
                            </template>
                        </s-text>

                    </v-card-text>
                    <v-card-actions>
                        <v-btn class="px-2" width="30%" style="color: white" :color="'#ffaa1a'" @click="createAccount()">
                            Registrarse
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <!-- RECOVERY PASSWORD -->
            <v-col lg="6" md="6" xs="12" cols="12" style="background:#fff" v-if="recovery">
                <v-card class="mx-auto my-0" width="90%" elevation="0" style="background:#fff;">
                    <v-card-title style="text-transform:;font-size:24px; font-weight: bold; color:#234798; margin: 16px 0;letter-spacing: -1px;">Recuperar contraseña</v-card-title>
                    <v-card-text>
                        <div class="my-4 text-subtitle-1">Enviaremos instrucciones por correo electrónico para restablecer su contraseña.</div>
                    </v-card-text>
                </v-card>
                <v-card class="px-10 py-0" style="box-shadow: 0 0px 0px 0 !important; margin-top: -20px">

                    <v-card-text>

                        <s-text v-model="UsrName" ref="UsrName" label="Nº DE DOCUMENTO" class="px-0">
                            <template v-slot:prepend-inner>
                                <v-icon class="py-1">mdi mdi-account</v-icon>
                            </template>
                        </s-text>

                        <s-text v-model="UsrEmail" ref="UsrEmail" label="CORREO ELECTRÓNICO" class="px-0 mt-4">
                            <template v-slot:prepend-inner>
                                <v-icon class="py-1">mdi mdi-email</v-icon>
                            </template>
                        </s-text>

                    </v-card-text>
                    <v-card-actions>
                        <v-btn class="px-2" width="50%" style="color: white" :color="'#ffaa1a'" @click="createAccount()">
                            Recuperar contraseña
                        </v-btn><strong class="px-3"><a @click="recovery=false" style="font-weight: bold;color: #234798">REGRESAR AL INICIO</a></strong>
                    </v-card-actions>
                </v-card>
            </v-col>

        </v-row>
        <v-row>
            <!-- <v-footer app padless absolute dark style="background: #23397c;">
            <v-container fluid>
                <v-row>
                    <v-col class="py-1 body-2" cols="12">
                        2022 — Inmobiliaria Miraflores Perù — Todos los derechos reservados.
                    </v-col>
                </v-row>
            </v-container>
        </v-footer> -->

        </v-row>
    </v-container>

</v-app>
</template>

<script>
import _sAuth from "@/services/AuthService";
import _sPayment from "@/services/Payment/PaymentService";
import ChangePassword from "@/views/Auth/ChangePassword";

export default {
    components: {
        ChangePassword
    },
    data: () => ({
        user: null,
        showChangePassword: false,
        UsrName: "",
        UsrPassword: "",
        visiblePassword: false,
        validatingUser: false,
        tokenNiubiz: null,

        login: true,
        UsrEmail: "",
        UsrEmailConf: "",

        recovery: false
    }),
    methods: {

        //**************************************** LOGIN *************************************** */
        //*********************************************************************************************** */
        close() {
            this.showChangePassword = false;
            this.validatingUser = false;
        },

        validateUser() {
            this.validatingUser = true;

            if (this.UsrName.length > 0 && this.UsrPassword.length > 0) {
                _sAuth
                    .login(this.UsrName, this.UsrPassword)
                    .then((response) => {
                        if (response.status == 200) {
                            this.user = response.data.user;

                            /* if (response.data.user.UsrExtern == 0) {
                                localStorage.clear();
                                this.validatingUser = false;
                                this.$fun.alert("Credenciales incorrectas", "error")
                                return
                            } */

                            localStorage.clear();
                            localStorage.setItem("token", response.data.token);
                            localStorage.setItem("UsrID", response.data.user.UsrID);
                            localStorage.setItem("UsrName", response.data.user.UsrName);
                            localStorage.setItem("isIdle", false);
                            localStorage.setItem("TimeToken", response.data.tokenRefresh * 60000 - 60000);
                            localStorage.setItem("TimeTokenCount", response.data.tokenRefresh * 60000 - 60000);
                            localStorage.setItem("extern", response.data.user.UsrExtern);

                            if (response.data.user.UsrExtern == 1) {

                                if (response.data.token !== null) {
                                    _sPayment
                                        .generatedToken(response.data.user.UsrID)
                                        .then(resp => {
                                            if (resp.status == 200) {
                                                this.tokenNiubiz = resp.data
                                                localStorage.setItem("tokenNiubiz", this.tokenNiubiz);
                                            }
                                        })
                                        .catch((error) => {
                                            alert("Error con Pasarela de Pago, Comuniquese con IMP")
                                        });
                                }

                            }

                            if (response.data.user.UsrPasswordChange == 0) {
                                this.$router.replace("/");
                            } else this.showChangePassword = true;
                        }
                    })
                    .catch((error) => {
                        this.validatingUser = false;
                    });

            } else this.validatingUser = false;
        },

        //**************************************** CREATE ACCOUNT *************************************** */
        //*********************************************************************************************** */

        createAccount() {
            alert("No disponible")
            return
            var obj = {

                MailSubject: "Creacion de Cuenta - Portal IMP",
                MailBody: '',
                MailDestination: "", //"irvinpanta96@gmail.com",
                MailSender: "", //"helpdesk@imperu.com.pe"
            };
            _sPayment
                .createAccount(obj)
                .then(resp => {
                    if (resp.status == 200) {

                    }
                })
                .catch((error) => {
                    this.$fun.alert(error, "error")
                });
        },
    },

};
</script>

<style scoped>
.background-light {
    /* background: #eceff1 !important; */
    /* background-image: url("https://images.ctfassets.net/hrltx12pl8hq/5KiKmVEsCQPMNrbOE6w0Ot/341c573752bf35cb969e21fcd279d3f9/hero-img_copy.jpg?fit=fill&w=800&h=300"); */
    background-image: url("../../assets/imp.jpg");
}

.background-dark {
    background:
        rgb(53, 54, 58) !important;
}

.style-toolbar {
    color: white;
    font-size: 12px !important;
}

.style-font {
    color: white;
    font-size: 12px !important;
}

.style-default {
    font-weight: 400 !important;
    font-size: 12px !important;
}
</style>
